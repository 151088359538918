<!--vip底部组件-->
<template>
  <div>
		<div class="vipBottomBox">
			<div class="colBox">
				<div class="colTitle">
					<div class="titleLeft" v-if="type == 1">
						<div class="title">VIP专享课程免费学习</div>
						<div class="subheading">
							Free Course
						</div>
					</div>
					<div class="titleLeft" v-else>
						<div class="title">VIP专享免费素材</div>
						<div class="subheading">
							Free Material
						</div>
					</div>
					<div class="titleRight">
						<div class="price">￥{{type == 1?6888:898}}/年</div>
						<div class="annotation">
							平均可省
						</div>
					</div>
				</div>
				<div class="colContent courseBox">
					<el-skeleton animated :loading="!(fidData.length && serviceImg.length)" :count="8" style="width:17.2rem;display: flex;">
						<div slot="template">
							<el-skeleton-item variant="image" style="width: 2.82rem; height: 2.2rem;margin-bottom: 0.32rem"/>
						</div>
					</el-skeleton>
					<template v-if="fidData.length && serviceImg.length">
						<div class="courseItem" v-for="(item, index) in fidData" :key="index">
							<div class="imgBox">
								<img :src="serviceImg[index].src" alt="">
								<div class="courseName"  @click="jump(serviceImg[index].url)">
									<div class="name">
										{{serviceImg[index].title}}
									</div>
									<div style="line-height: 18px;">...</div>
								</div>
								<div class="vip">VIP专享</div>
							</div>
							<div class="courseBottom">
								<div>
									<div class="num">{{item.count}}个{{serviceImg[index].title}}</div>
									<div class="value">价值{{item.sum}}元</div>
								</div>
								<div class="courseBut" @click="jump(serviceImg[index].url)">
									{{type == 1 ? '免费学习':'免费下载'}}
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="colBox">
				<div class="colTitle">
					<div class="titleLeft" v-if="type == 1">
						<div class="title">付费课程折上折</div>
						<div class="subheading">
							Paid Course
						</div>
					</div>
					<div class="titleLeft" v-else>
						<div class="title">付费素材下载8折</div>
						<div class="subheading">
							Paid Material
						</div>
					</div>
					<div class="titleRight">
						<div class="price">￥{{type == 1 ? 2988 : 598}}/年</div>
						<div class="annotation">
							平均可省
						</div>
					</div>
				</div>
				<div v-if="serviceImg.length" class="colContent discount">
					<div>
						<div class="discountImgBox">
							<img :src="serviceImg[8].src" alt="">
							<div class="discountName" @click="jump(serviceImg[8].url)">
								<div class="name">
									{{serviceImg[8].title}}
								</div>
								<div class="value">
									低至<span>8</span>折
								</div>
							</div>
						</div>
						<div class="discountImgBox">
							<img :src="serviceImg[9].src" alt="">
							<div class="discountName" @click="jump(serviceImg[9].url)">
								<div class="name">
									{{serviceImg[9].title}}
								</div>
								<div class="value">
									低至<span>8</span>折
								</div>
							</div>
						</div>
					</div>
					<div>
						<img v-if="type == 1" class="discount8" src="../../../assets/image/vip/8.png" alt="">
						<img v-else class="discount8" src="../../../assets/image/vip/m8.png" alt="">
					</div>
					<div>
						<div class="discountImgBox">
							<img :src="serviceImg[10].src" alt="">
							<div class="discountName" @click="jump(serviceImg[10].url)">
								<div class="name">
									{{serviceImg[10].title}}
								</div>
								<div class="value">
									低至<span>8</span>折
								</div>
							</div>
						</div>
						<div class="discountImgBox">
							<img :src="serviceImg[11].src" alt="">
							<div class="discountName" @click="jump(serviceImg[11].url)">
								<div class="name">
									{{serviceImg[11].title}}
								</div>
								<div class="value">
									低至<span>8</span>折
								</div>
							</div>
						</div>
						</div>
				</div>
			</div>
			<div class="colBox">
				<div class="colTitle">
					<div class="titleLeft" v-if="type == 1">
						<div class="title">付费课程重复学习免费</div>
						<div class="subheading">
							Repeat Study Free
						</div>
					</div>
					<div class="titleLeft" v-else >
						<div class="title">付费素材重复下载免费</div>
						<div class="subheading">
							Repeat Download Free
						</div>
					</div>
					<div></div>
				</div>
				<div class="colContent freeBox">
					<img v-if="type == 1" class="free" src="../../../assets/image/vip/free.png" alt="">
					<img v-else class="free" src="../../../assets/image/vip/free1.png" alt="">
				</div>
			</div>
			<div class="colBox">
				<div class="colTitle">
					<div class="titleLeft">
						<div class="title">常见问题</div>
						<div class="subheading">
							Common Problem
						</div>
					</div>
					<div></div>
				</div>
				<div class="colContent problemBox">
					<div class="problem">
						<div class="problemTitle">1.课程和素材会更新吗？</div>
						<div class="answer">知设网会持续更新优质的设计课程以及国内外，最新、最优秀的素材。</div>
					</div>
					<div class="problem">
						<div class="problemTitle">2.VIP开通后可以退款吗？</div>
						<div class="answer">开通后是由系统自动发放，暂不支持退换，开通前请注意查看权限详情。</div>
					</div>
					<div class="problem">
						<div class="problemTitle">3.VIP开通后在哪里看？</div>
						<div class="answer">头像 - 个人中心 -  我的VIP中心！就可以查看到自己开通的VIP</div>
					</div>
					<div class="problem">
						<div class="problemTitle">4.如何申请开票？</div>
						<div class="answer">添加客服微信 — 提交开票信息 — 即可获得对应发票！</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import { getOther, vipAuthor } from "../../../Api";
export default {
  name: "VipBottom",
  props: ["serviceImg", "type", "fidData"],
  data() {
    return {
      serviceDialog: false,
      showMoreVip: false,
      questionList: [],
      vipList: [],
    };
  },
  methods:{
		jump(url) {
			window.open(url, '_blank')
		}
  },
  created() {
    getOther(1145).then((res) => {
      this.questionList = res.data;
    });
    vipAuthor().then((res) => {
      this.vipList = res.data;
    });
  },
};
</script>

<style scoped>
.vipBottomBox {
	width: 12rem;
	margin: 0.6rem auto;
}
.colBox {
	
}
.colTitle {
	width: 100%;
	height: 0.65rem;
	display: flex;
	justify-content: space-between;
}
.titleLeft {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.titleLeft .title{
	font-size: 0.28rem;
	font-weight: 500;
	text-align: left;
	letter-spacing: 0px;
	color: rgba(56, 56, 56, 1);
}
.titleLeft .subheading{
	text-align: left;
	font-size: 0.16rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(166, 166, 166, 1);
}
.titleRight {
	width: 1.27rem;
	height: 0.65rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px 32px 8px 32px;
	background: rgba(255, 245, 207, 1);
}
.titleRight .price{
	font-size: 0.24rem;
	white-space: nowrap;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(126, 93, 38, 1);
}
.titleRight .annotation{
	font-size: 0.14rem;
	white-space: nowrap;
	font-weight: 400;
	letter-spacing: 0px;
	color: rgba(126, 93, 38, 1);
}
.colContent {
	margin: 0.4rem 0 0;
}
/deep/.el-skeleton.is-animated {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.courseBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.courseItem {
	width: 2.82rem;
	height: 2.2rem;
	margin-bottom: 0.32rem;
	border-radius: 0.08rem;
	overflow: hidden;
	background: rgba(255, 251, 242, 1);
}
.courseItem .imgBox{
	position: relative;
	width: 2.82rem;
	height: 1.6rem;
}
.courseItem .imgBox .vip{
	position: absolute;
	top: 0;
	left: 0;
	width: 1rem;
	height: 0.36rem;
	line-height: 0.36rem;
	border-radius: 0 0 0.08rem 0;
	background: rgba(254, 210, 108, 1);
	text-shadow: 0 0 0.1rem rgba(255, 255, 255, 1);
	font-size: 0.16rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: rgba(126, 93, 38, 1);
}
.courseItem .imgBox img{
	position: relative;
	width: 2.82rem;
	height: 1.6rem;
	border-radius: 0.08rem 0.08rem 0 0;
	margin: 0;
	object-fit: cover;
}
.courseName {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 0.36rem;
	line-height: 0.24rem;
	background: rgba(0, 0, 0, 0.4);
	cursor: pointer;
}
.courseName .name {
	font-size: 0.24rem;
	font-weight: 700;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, 1);
}
.courseBottom {
	width: 2.82rem;
	padding: 0.08rem 0.16rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.courseBottom .num{
	font-size: 0.14rem;
	font-weight: 500;
	text-align: left;
	color: rgba(126, 93, 38, 1);
}
.courseBottom .value{
	font-size: 0.14rem;
	font-weight: 400;
	text-align: left;
	color: rgba(126, 93, 38, 1);
}
.courseBottom .courseBut{
	cursor: pointer;
	width: 0.9rem;
	height: 0.32rem;
	border-radius: 0.16rem;
	background: linear-gradient(270deg, rgba(232, 153, 16, 1) 0%, rgba(252, 201, 81, 1) 100%);
	box-shadow: 0px 0.02rem 0.04rem rgba(150, 102, 18, 0.35);
	font-size: 0.12rem;
	font-weight: 500;
	line-height: 0.32rem;
	color: rgba(255, 255, 255, 1);
}
.discount {
	display: flex;
	justify-content: space-between;
}
.discountImgBox {
	position: relative;
	width: 3.84rem;
	height: 2rem;
	margin-bottom: 0.24rem;
}
.discountImgBox img {
	width: 3.84rem;
	height: 2rem;
	border-radius: 0.08rem;
	object-fit: cover;
}
.discountName {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 0.08rem;
	background: rgba(0, 0, 0, 0.4);
	cursor: pointer;
}
.discountName .name{
	padding: 0 0.32rem 0.12rem;
	border-bottom: 1px solid rgba(255, 255, 255, 1);   
	font-size: 0.26rem;
	font-weight: 700;
	
}
.discountName .value{
	font-size: 0.15rem;
	font-weight: 500;
}
.discountName .value span{
	font-size: 0.48rem;
	font-weight: 500;
	margin: 0 0.05rem;
}
.discount8 {
	width: 3.84rem;
	height: 4.24rem;
}
.freeBox {
	display: flex;
	justify-content: center;
	margin: 0.4rem 0;
}
.free {
	width: 4.8rem;
	height: 3.53rem;
}
.problemBox {
	padding-bottom: 0.24rem;
	border-radius: 0.08rem;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 0px 0.1  rgba(219, 219, 219, 0.28);
	display: flex;
	flex-wrap: wrap;
}
.problem {
	width: 50%;
	padding: 0.24rem 0 0 0.6rem;
	text-align: left;
}
.problemTitle {
	font-size: 0.16rem;
	font-weight: 500;
	color: rgba(4, 4, 4, 1);
	margin-bottom: 0.16rem;
}
.answer {
	font-size: 0.14rem;
	font-weight: 500;
	color: rgba(102, 102, 102, 1);
}
</style>